import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Row, Col } from 'reactstrap'

import ArticleAboutModal from './article-about-modal'
import {
  ArticleWrapper,
  ArticleContainer,
  ArticlePersonWrapper,
  ArticlePersonPhotoColWrapper,
  ArticlePersonPhotoWrapper,
  ArticlePersonAboutColWrapper,
  ArticlePersonDescColWrapper,
  ArticlePersonDescWrapper,
} from '../elements'

const Article = (props) => {
  const data = useStaticQuery(graphql`
    query {
      ArticlePerson1: file(
        relativePath: { eq: "parent/article2/person2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image1: file(relativePath: { eq: "parent/article2/image1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image2: file(relativePath: { eq: "parent/article2/image2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image3: file(relativePath: { eq: "parent/article2/image3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 475) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image4: file(relativePath: { eq: "parent/article2/image4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 610) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <ArticleWrapper>
      <ArticleContainer>
        <ArticlePersonWrapper>
          <Row>
            <ArticlePersonPhotoColWrapper>
              <ArticlePersonPhotoWrapper>
                <Img
                  alt="Łukasz Grygiel - ekonomista"
                  fluid={data.ArticlePerson1.childImageSharp.fluid}
                  className="articlePersonImage"
                />
              </ArticlePersonPhotoWrapper>
            </ArticlePersonPhotoColWrapper>
          </Row>
          <Row>
            <ArticlePersonAboutColWrapper>
              <div className="personInfo">
                <span className="personName">Łukasz Grygiel</span>{' '}
                <span className="personPosition">ekonomista</span>
                <br />
                <ArticleAboutModal>
                  Jestem autorem bloga o{'\u00A0'}edukacji finansowej dzieci i
                  {'\u00A0'}
                  młodzieży dla rodziców &ndash; Sto Monet (lukaszgrygiel.com).
                  Prowadzę lekcje i{'\u00A0'}wykłady w{'\u00A0'}tej tematyce.
                  Pasjonują mnie finanse osobiste. W{'\u00A0'}wolnych chwilach
                  biegam i{'\u00A0'}
                  słucham podcastów.
                </ArticleAboutModal>
              </div>
            </ArticlePersonAboutColWrapper>
            <ArticlePersonDescColWrapper>
              <p>
                Jestem rodzicem i{'\u00A0'}autorem bloga o{'\u00A0'}edukacji
                finansowej dzieci i{'\u00A0'}młodzieży dla rodziców
                <br />- Sto Monet (lukaszgrygiel.com). Prowadzę lekcje i
                {'\u00A0'}wykłady w{'\u00A0'}tej tematyce. Zachęcam
                <br />
                innych rodziców do{'\u00A0'}edukacji finansowej swoich dzieci.
                Pasjonują mnie finanse osobiste.
                <br />W{'\u00A0'}wolnych chwilach biegam i{'\u00A0'}słucham
                podcastów
              </p>
            </ArticlePersonDescColWrapper>
          </Row>
        </ArticlePersonWrapper>
        <ArticlePersonDescWrapper>
          <Row>
            <Col xs={12}>
              <p>
                Celem edukacji finansowej dzieci powinno być przygotowanie ich
                do{'\u00A0'}zarządzania swoimi pieniędzmi w{'\u00A0'}dorosłym
                życiu. Jednym ze{'\u00A0'}skutecznych narzędzi rodziców
                wspierających ten cel jest budżet domowy. Pokazanie dzieciom, na
                {'\u00A0'}co wydajemy nasze pieniądze jest nie tylko okazją do
                {'\u00A0'}rozmów z{'\u00A0'}dziećmi o{'\u00A0'}pieniądzach, ale
                także do{'\u00A0'}kształtowania określonych postaw finansowych.
              </p>
            </Col>
            <Col xs={12}>
              <h2>Cel prowadzenia budżetu domowego</h2>
            </Col>
            <Col xs={12} lg={6}>
              <p>
                Nadrzędnym celem prowadzenia budżetu powinno być planowanie i
                {'\u00A0'}decydowanie, na{'\u00A0'}co wydajemy nasze pieniądze,
                po{'\u00A0'}to, by{'\u00A0'}ich oszczędzanie było jeszcze
                łatwiejsze. Warto już na{'\u00A0'}początku wyjaśnić dziecku, po
                {'\u00A0'}co to{'\u00A0'}wszystko robimy. Dla starszych dzieci
                pomocne będzie pokazanie, w{'\u00A0'}jaki sposób zapisujemy
                nasze wpływy i{'\u00A0'}wydatki. Najważniejsze, aby dzieci
                wiedziały, że{'\u00A0'}mając ograniczony dochód, powinniśmy
                podejmować takie decyzje finansowe, aby zaspokajać swoje
                potrzeby, jednocześnie oszczędzając na{'\u00A0'}większe wydatki
                w{'\u00A0'}przyszłości.
                <br />
                Jak robić to{'\u00A0'}w{'\u00A0'}praktyce?
              </p>
            </Col>
            <Col xs={12} lg={6}>
              <Img
                fluid={data.Article1Image1.childImageSharp.fluid}
                className="articleImage"
                alt="Cel prowadzenia budżetu domowego"
              />
            </Col>
            <Col xs={12}>
              <h2>Pokaż, skąd masz pieniądze?</h2>
            </Col>
            <Col xs={12}>
              <p>
                Jeżeli źródłem Twojego dochodu jest praca na{'\u00A0'}etacie,
                opowiedz lub pokaż dziecku, czym się zajmujesz i{'\u00A0'}jakie
                masz obowiązki. Opowiedz, czym zajmuje się Twoja firma i
                {'\u00A0'}na czym zarabia. <br />
                Jeżeli posiadasz dodatkowe źródła dochodu, również opowiedz o
                {'\u00A0'}nich dziecku. Kwestią sporną może być, czy mówić
                dzieciom, ile konkretnie zarabiamy. Mój syn, mający 8 lat,
                wprost mnie o{'\u00A0'}to zapytał, więc uznałem, że{'\u00A0'}mu
                {'\u00A0'}powiem. Z{'\u00A0'}kolei amerykańska autorka książki
                „Make your kid a{'\u00A0'}money genius (Even If{'\u00A0'}You're
                Not): A{'\u00A0'}Parents' Guide for Kids 3 to{'\u00A0'}23” Beth
                Kobliner twierdzi, że{'\u00A0'}do{'\u00A0'}pewnego wieku nie
                powinniśmy mówić, ile zarabiamy, bo{'\u00A0'}czasem są{'\u00A0'}
                to{'\u00A0'}abstrakcyjne kwoty dla dziecka, oraz ile zarabia
                nasz partner/partnerka, po{'\u00A0'}to, aby dziecko nie
                zrozumiało, że{'\u00A0'}któryś z{'\u00A0'}rodziców jest
                „bardziej wartościowy”.
              </p>
            </Col>
            <Col xs={12} sm={6}>
              <div className="imgWrapper">
                <Img
                  fluid={data.Article1Image2.childImageSharp.fluid}
                  className="articleImage"
                />
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <h2 className="noMarginTop">Na co{'\u00A0'}wydajesz?</h2>
              <p>
                Bardziej obszerną częścią budżetu jest część wydatkowa. Warto
                więc w{'\u00A0'}sposób praktyczny wyjaśniać dziecku, na
                {'\u00A0'}co wydajemy pieniądze w{'\u00A0'}ramach poszczególnych
                kategorii budżetowych.
              </p>

              <h3>Wydatki na{'\u00A0'}jedzenie</h3>
              <p>
                Idealnym miejscem do{'\u00A0'}pokazania i{'\u00A0'}uświadomienia
                dziecku, ile kosztują podstawowe produkty, jest sklep. Tutaj
                możemy wyjaśnić, dlaczego jedne produkty są{'\u00A0'}droższe od
                {'\u00A0'}innych oraz jak funkcjonują promocje.
              </p>

              <h3>Przykłady ćwiczeń:</h3>
              <ul>
                <li>
                  Będąc w{'\u00A0'}sklepie z{'\u00A0'}dzieckiem przy kasie,
                  zgadnijcie, ile zapłacicie za{'\u00A0'}zakupy. Kto będzie
                  bliżej ostatecznej kwoty, wygrywa. Ustalcie drobną nagrodę.
                </li>
                <li>
                  Zaangażuj dziecko w{'\u00A0'}tworzenie listy zakupów. Spisując
                  produkty, poproś dziecko, aby określiło cenę każdego z
                  {'\u00A0'}nich. Po{'\u00A0'}przyjściu ze{'\u00A0'}sklepu
                  porównajcie. Określ wcześniej, o{'\u00A0'}ile dziecko może się
                  pomylić. Jeżeli zapisana wcześniej kwota będzie zbliżona do
                  {'\u00A0'}tej zapłaconej, wynagrodź dziecko.
                </li>
              </ul>

              <h3>Wydatki na{'\u00A0'}mieszkanie</h3>
              <p>
                Przy tej kategorii możemy uświadomić dziecko, jakie ponosimy
                koszty utrzymania domu. A{'\u00A0'}więc na{'\u00A0'}przykład,
                ile wynosi nasz czynsz i{'\u00A0'}co wchodzi w{'\u00A0'}jego
                skład. W{'\u00A0'}tym celu warto wydrukować rachunek i{'\u00A0'}
                pokazać dziecku poszczególne części składowe. Dodatkowo możemy
                zaznaczyć te{'\u00A0'}koszty, na{'\u00A0'}które mamy bezpośredni
                wpływ. To{'\u00A0'}również dobra okazja, aby ustalić nasze
                wspólne domowe zasady mające na{'\u00A0'}celu wspólne
                oszczędzanie, np.
              </p>
            </Col>
            <Col xs={12} sm={6}>
              <h3 className="noMarginTop">Oszczędzanie wody</h3>
              <ul>
                <li>Jeśli myjemy zęby, zakręcamy kran.</li>
                <li>Wannę napełniamy maksymalnie do{'\u00A0'}połowy.</li>
                <li>Z prysznica korzystamy maksymalnie 5 minut.</li>
                <li>
                  Jeśli w{'\u00A0'}domu jest zmywarka, unikamy zmywania
                  ręcznego.
                </li>
              </ul>

              <h3>Oszczędzanie prądu</h3>
              <ul>
                <li>
                  Kiedy wychodzimy z{'\u00A0'}pomieszczeń, gasimy światło.
                </li>
                <li>
                  Po naładowaniu telefonu wyciągamy ładowarkę z{'\u00A0'}
                  kontaktu.
                </li>
                <li>
                  Nie zasłaniamy okien i{'\u00A0'}korzystamy ze{'\u00A0'}światła
                  dziennego tak długo, jak tylko się da.
                </li>
                <li>
                  Nie wkładamy do{'\u00A0'}lodówki ciepłych potraw i{'\u00A0'}
                  napojów.
                </li>
                <li>
                  Nie zostawiamy otwartych drzwi do{'\u00A0'}lodówki zbyt długo
                  i{'\u00A0'}zawsze ją{'\u00A0'}zamykamy.
                </li>
                <li>Robimy pranie, gdy pralka jest pełna.</li>
                <li>
                  Wstawiamy zmywarkę, gdy jest w{'\u00A0'}pełni załadowana.
                </li>
                <li>Włączamy telewizor tylko wtedy, kiedy coś oglądamy.</li>
              </ul>

              <h3>Oszczędzanie na{'\u00A0'}ogrzewaniu</h3>
              <ul>
                <li>
                  Zanim będziemy chcieli przewietrzyć mieszkanie, zakręcamy
                  kaloryfery.
                </li>
                <li>Wietrzymy mieszkanie krótko, otwierając szeroko okno.</li>
                <li>Odkrywamy grzejniki i{'\u00A0'}niczym nie zasłaniamy.</li>
                <li>
                  Kiedy wyjeżdżamy lub wychodzimy z{'\u00A0'}domu, zakręcamy
                  kaloryfery.
                </li>
                <li>Kiedy idziemy spać, zakręcamy kaloryfery.</li>
              </ul>
            </Col>
            <Col xs={12} lg={6}>
              <Img
                fluid={data.Article1Image3.childImageSharp.fluid}
                className="articleImage"
              />
            </Col>
            <Col xs={12}>
              <h3>Rachunki za{'\u00A0'}telefon</h3>
              <p>
                Jeżeli nasze dziecko otrzymało już od{'\u00A0'}nas swój pierwszy
                telefon, możemy rozważyć, czy nie powiększyć kieszonkowego o
                {'\u00A0'}kwotę rachunku za{'\u00A0'}telefon dziecka, po
                {'\u00A0'}to, aby miało ono poczucie, że{'\u00A0'}partycypuje w
                {'\u00A0'}kosztach. Jeżeli rachunek byłby przekroczony i
                {'\u00A0'}wyższy niż regularna i{'\u00A0'}comiesięczna opłata,
                dziecko musiałoby opłacić go{'\u00A0'}z{'\u00A0'}pozostałej
                kwoty kieszonkowego.
                <br />
                Warto także pokazać dziecku, jak realizuje się przelew przez
                internet przy okazji opłacania wszelkich rachunków.
              </p>

              <h3>Zobowiązania</h3>
              <p>
                Ważną kategorią w{'\u00A0'}budżecie są{'\u00A0'}nasze
                zobowiązania. Jeżeli spłacamy kredyty, jest to{'\u00A0'}dobra
                okazja do{'\u00A0'}wyjaśnienia dziecku zasad ich funkcjonowania.
                Możemy także rozważyć, czy nie zaproponować dziecku niewielkiej
                pożyczki. W{'\u00A0'}tym celu najlepiej sporządzić umowę na
                {'\u00A0'}piśmie, określając zasady jej spłaty z{'\u00A0'}
                kieszonkowego. To{'\u00A0'}najbardziej bezpieczna i{'\u00A0'}
                praktyczna forma nauki dziecka o{'\u00A0'}kredytach.{' '}
              </p>

              <h3>Wydatki nieregularne</h3>
              <p>
                Dzieciom warto uświadomić, że{'\u00A0'}część opłat ponosimy co
                {'\u00A0'}miesiąc, a{'\u00A0'}niektóre wydatki pojawiają się
                kilka razy w{'\u00A0'}roku lub co{'\u00A0'}kilka lat. Przykładem
                są{'\u00A0'}np. wydatki na{'\u00A0'}odzież, remonty, nowe
                sprzęty, rodzinne uroczystości (np. wesela, komunie).
                <br />
                Jednym ze{'\u00A0'}sposobów, by{'\u00A0'}zaangażować dziecko w
                {'\u00A0'}rozmowę o{'\u00A0'}tego typu wydatkach, jest na
                {'\u00A0'}przykład wspólne przygotowanie listy domowych sprzętów
                i{'\u00A0'}zastanowienie się, które z{'\u00A0'}nich najbardziej
                szwankują lub z{'\u00A0'}których najczęściej korzystacie, po
                {'\u00A0'}to, aby spróbować przewidzieć, który sprzęt będzie
                potrzebował jako pierwszy naprawy lub wymiany. Przy każdym
                napiszcie kwotę, która stanowiłaby koszt zakupu nowego.
                <br />
                Dzięki takiemu ćwiczeniu w{'\u00A0'}praktyczny sposób pokażecie,
                jak można ustalić finansowy cel oszczędzania. To{'\u00A0'}
                najważniejszy element budżetu.
              </p>
            </Col>
            <Col xs={12}>
              <div className="imgWrapper imgCenter">
                <Img
                  fluid={data.Article1Image4.childImageSharp.fluid}
                  className="articleImage"
                />
              </div>
            </Col>
            <Col xs={12}>
              <h2>Wspólne oszczędzanie</h2>
              <p style={{ marginBottom: 0 }}>
                Pokazanie, na{'\u00A0'}co i{'\u00A0'}w jaki sposób oszczędzają
                dorośli, może być skuteczną motywacją i{'\u00A0'}zachętą do
                {'\u00A0'}oszczędzania przez dziecko.
                <br />
                Aby tak się stało, dziecko również powinno określić cel, który
                musi być dla niego atrakcyjny. Czas trwania oszczędzania nie
                może być zbyt długi, by{'\u00A0'}zniechęcić dziecko do{'\u00A0'}
                regularnego odkładania części swojego kieszonkowego.
                <br />
                Co warto zatem zrobić?
              </p>
              <ul>
                <li>
                  Zacznij oszczędzać razem z{'\u00A0'}dzieckiem. Ustalcie, że
                  {'\u00A0'}zaczynacie od{'\u00A0'}dziś na{'\u00A0'}wybrane
                  przez siebie cele, przez określony przez was czas, np. ty
                  {'\u00A0'}– na{'\u00A0'}nową kanapę do{'\u00A0'}domu, dziecko
                  – na{'\u00A0'}hulajnogę.
                </li>
                <li>
                  Zaproponuj dziecku, że{'\u00A0'}po{'\u00A0'}upływie okresu, w
                  {'\u00A0'}którym postanawiacie oszczędzać, w{'\u00A0'}nagrodę
                  zapłacisz mu{'\u00A0'}drugie tyle, ile uzbiera. Niech
                  oszczędzanie będzie dla niego jeszcze bardziej atrakcyjne i
                  {'\u00A0'}zachęcające.
                </li>
                <li>
                  Wydrukujcie zdjęcia waszych celów i{'\u00A0'}powieście np. na
                  {'\u00A0'}lodówce. Dzieci są{'\u00A0'}wzrokowcami. Zdjęcia
                  będą przypominać wam o{'\u00A0'}waszych postanowieniach.
                </li>
                <li>
                  Powieś na{'\u00A0'}ścianie w{'\u00A0'}pokoju dziecka tablicę i
                  {'\u00A0'}zapisujcie na{'\u00A0'}niej stan swoich
                  oszczędności. Niech każdy je{'\u00A0'}widzi. Odnotowuj
                  przyrost, nie ścierając poprzednich wartości.
                </li>
              </ul>
              <p>
                Pamiętaj, że{'\u00A0'}o{'\u00A0'}sukcesie w{'\u00A0'}finansach
                osobistych w{'\u00A0'}80% decyduje postawa, a{'\u00A0'}tylko 20%
                wiedza, a{'\u00A0'}to oznacza, że{'\u00A0'}o{'\u00A0'}wiele
                bardziej wartościowe będzie doświadczanie przez dziecko różnych
                sytuacji związanych z{'\u00A0'}podejmowaniem decyzji niż nauka
                np. terminologii finansowych. Nie bójmy się zatem włączania
                dziecka do{'\u00A0'}naszych dorosłych dyskusji o{'\u00A0'}
                pieniądzach, traktując je{'\u00A0'}jako ważnego partnera w
                {'\u00A0'}rozmowie.
              </p>
            </Col>
          </Row>
        </ArticlePersonDescWrapper>
      </ArticleContainer>
    </ArticleWrapper>
  )
}

export default Article
